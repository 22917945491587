import React, { useState, useEffect, useRef } from "react";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import SupportPage from "./SupportRequest";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from "../../../customComponent/CustomAlertMessage";
import { Link } from "react-router-dom";
import DataTableBase from "../../../customComponent/DataTable";
import moment from "moment";
const Supportmessage = (props) => {

  const { id, email, description, orderId, userId, ticketId, ticketStatus } = props;
  console.log("🚀 ~ Supportmessage ~ ticketId:", ticketId)
  const [activeScreen, setActiveScreen] = useState('supportmessage');
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState('');
  const [messageQuery, setMessageQuery] = useState([]);
  const messagesEndRef = useRef(null)
  const [isRotating, setRotating] = useState(false);
  const [details, setDetails] = useState([])
  const [userType, setuserType] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const handleInputSellChange = (event) => {
    switch (event.target.name) {
      case "message":
        setMessage(event.target.value);
        break;
      default:
        break;
    }
  }


  const handleMessageQuery = async (message, id) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.replyTicket(message, id).then(async result => {
      if (result?.success) {
        try {
          LoaderHelper.loaderStatus(false);
          setMessage("");
          getMessageQuery();
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.msg);
      }
    });
  }


  const getOrderDetails = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.orderDetails(orderId).then(async result => {
      if (result?.success) {
        try {
          LoaderHelper.loaderStatus(false);
          if (result?.data?.length === 0) {
            alertWarningMessage("Invalid order id")
          }
          else {
            let data = result?.data[0]
            get_messages(data?.trader_id, data?.postAd_user, orderId)
            setDetails(result?.data || [])
            setuserType(`${(data?.side === "BUY" && data?.trader_id === userId) ? "Seller" : (data?.side === "BUY" && data?.postAd_user === userId) ? "Buyer" : (data?.side === "SELL" && data?.trader_id === userId) ? "Buyer" : "Seller"}`)
          }
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  }


  const get_messages = async (trader_id, merchant_id, order_id) => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.get_messages(trader_id, merchant_id, order_id)
      if (result?.success) {
        setChatHistory(result?.data)
      }
    } finally { LoaderHelper.loaderStatus(false); }
  };

  const resolveDispute = async (orderId, trasferTo) => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.resolveDispute(orderId, trasferTo)
      if (result?.success) {
        alertSuccessMessage(result?.message)
        handleStatus()
      }else{
        alertErrorMessage(result?.message)
      }
    } finally { LoaderHelper.loaderStatus(false); }
  };


  const handleStatus = async () => {
    await AuthService.updateTicketStatus(ticketId, "Resolved").then(async result => {
      if (result?.success) {
        setDisableButton(true);
      } else {
        alertErrorMessage(result.message)
      }
    })
  };

  useEffect(() => {
    getMessageQuery();
    getOrderDetails();
  }, []);

  const getMessageQuery = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.get_support_by_id(id).then(async result => {
      if (result.success) {
        try {
          LoaderHelper.loaderStatus(false);
          if (id) {
            setMessageQuery(result?.data?.ticket)
          }
          setRotating(false);

        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
        setRotating(false);

      }
    });
  }

  const statuslinkFollow = (row) => {
    return (
      <>
        <button class="btn btn-sm btn-success mx-1 mb-1" disabled={disableButton || ticketStatus !== "Open"} onClick={() => { resolveDispute(row?._id, "SELLER") }} >Transfer to Seller</button>
        <button class="btn btn-sm btn-primary" disabled={disableButton || ticketStatus !== "Open"} onClick={() => { resolveDispute(row?._id, "BUYER") }}>Transfer to Buyer</button>
      </>

    );
  };

  const columns = [
    { name: "Order Id", wrap: true, selector: row => row?.order_id, },
    { name: "Side", wrap: true, selector: row => row?.side, },
    { name: "Buyer Status", wrap: true, selector: row => row?.side === "BUY" ? row?.merchant_status : row?.trader_status },
    { name: "Buyer User Id", wrap: true, selector: row => row?.side === "BUY" ? row?.postAd_user : row?.trader_id },
    { name: "Seller Status", wrap: true, selector: row => row?.side === "SELL" ? row?.merchant_status : row?.trader_status, },
    { name: "Seller User Id", wrap: true, selector: row => row?.side === "SELL" ? row?.postAd_user : row?.trader_id, },
    { name: "Amount", wrap: true, selector: row => `${row?.amount} ${row?.base_currency}`, },
    { name: "Price", wrap: true, selector: row => `${row?.fixed_price} ${row?.quote_short_name}`, },
    { name: "Action", width: "200px", wrap: true, selector: statuslinkFollow, },
  ]


  return (
    activeScreen === 'supportmessage' ?
      <>
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title">
                        <Link to="" onClick={() => setActiveScreen('support')}>
                          <i class="fa fa-arrow-left"></i>
                        </Link>
                        <div className="page-header-icon"></div>
                        Support Message
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <div className="container-xl px-4 mt-n10">
              <div className="row" >
                {/* description */}
                <div className="col-xl-12">
                  <div className="card mb-4">
                    <div class="card-header">Description
                      <div class="dropdown"></div>
                    </div>
                    {description?.length === 0 ? <h6 style={{ textAlign: 'center', padding: '164px 0' }}>No Description Available</h6> :
                      <div className="card-body mt-3">
                        <div class="right">
                          <div class="middle">
                            <div class="tumbler">
                              <div class="messages scroll_y" >

                                {description}

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                {/*   handle Message */}
                <div className="col-xl-6">
                  <div className="card mb-4 cwc_card ">
                    <div class="card-header"> Buyer and Seller Chat
                      <div class="dropdown"></div>
                    </div>
                    <div className="card-body mt-3">
                      <div class="right">

                        <div class="middle">
                          <div class="tumbler">
                            <div class="messages scroll_y" >
                              {chatHistory?.length > 0 ?
                                chatHistory.map(item =>
                                  <div >
                                    {item?.senderId === userId ?
                                      <div class="clip received">
                                        <div class="text">{item?.message?.text}</div>
                                        <small className="msg_info info-left" >{userType} : {moment(item?.updatedAt).format("MMM Do YYYY hh:mm A")} </small>
                                      </div>
                                      :
                                      <div class="clip sent">
                                        <div class="text">{item?.message?.text}</div>
                                        <small className="msg_info info-left" > {userType === "Buyer" ? "Seller" : "Buyer"}   : {moment(item?.updatedAt).format("MMM Do YYYY hh:mm A")}</small>
                                      </div>
                                    }
                                  </div>
                                )
                                : null}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-xl-6">
                  <div className="card mb-4 admin_chat">
                    <div class="card-header">{email}
                      {details?.length > 0 && `(${userType})`}

                      <div className={`cursor-pointer refresh ms-auto ${isRotating ? 'rotating' : ''}`} onClick={() => { getMessageQuery(); setRotating(true); }}>
                        <small><i class="ri-loop-right-line me-1"></i> Refresh</small> </div>
                      <div class="dropdown"></div>
                    </div>
                    <div className="card-body mt-3">
                      <div class="right">

                        <div class="middle">
                          <div class="tumbler">
                            <div class="messages scroll_y" >
                              {messageQuery?.length > 0 ?
                                messageQuery.map(item =>
                                  <div ref={messagesEndRef}>
                                    {item?.replyBy === 1 ?
                                      <div class="clip received">
                                        <div class="text">{item?.query}</div>
                                      </div>
                                      :
                                      <div class="clip sent">
                                        <div class="text">{item?.query}</div>
                                      </div>
                                    }
                                  </div>
                                )
                                : null}

                            </div>
                          </div>
                        </div>
                        <div class="bottom">
                          <div class="cup">
                            <input type="text" id="message" cols="30" rows="1" placeholder="Message..." name="message" value={message} onChange={handleInputSellChange} />
                            <button class="send" onClick={() => handleMessageQuery(message, id)}>Send</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {details?.length > 0 ?
              <div className="container-xl px-4 mt-2">
                <div className="row" >
                  <div className="col-12">
                    <div className="card mb-4">
                      <div class="card-header">Order Details
                        <div class="dropdown"></div>
                      </div>
                      {description.length === 0 ? <h6 style={{ textAlign: 'center', padding: '164px 0' }}>No Description Available</h6> :
                        <div className="card-body mt-3">
                          <div className="table-responsive"  >
                            <DataTableBase columns={columns} data={details} direction="auto" responsive subHeaderAlign="right" subHeaderWrap striped highlightOnHover fixedHeader />
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                </div>
              </div> : ""}

          </main>
        </div>
      </>
      : <SupportPage />
  )
}

export default Supportmessage;
